<template>
  <v-row justify="center">

    <v-snackbar
      v-model="snack.snackbar"
      :vertical="snack.vertical"
      :color="snack.color"
      :right="true"
      :top="true"
    >
      {{ snack.txt }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snack.snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Laboratorios</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                label="Laboratorio"
                v-model="form_lab.name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="7" md="7">
                <v-text-field
                label="Dirección"
                v-model="form_lab.direccion"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1">
                <v-btn
                color="success"
                :loading="loading5"
                :disabled="loading5"
                outlined
                fab
                x-small
                @click="savenewLab()">
                <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                    <v-simple-table fixed-header height="300px">
                        <template v-slot:default>
                        <thead>
                            <tr>
                            <th class="text-left">Laboratorio</th>
                            <th class="text-left">Dirección</th>
                            <th class="text-left"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="item in data_ensayo.laboratoriosList"
                            :key="'Lab_'+item.id">
                                <td width="30%">
                                    <v-text-field
                                    label="Laboratorio"
                                    v-model="item.laboratorio"
                                    ></v-text-field>
                                </td>
                                <td width="50%">
                                    <v-text-field
                                    label="Dirección"
                                    v-model="item.direccion"
                                    ></v-text-field>
                                </td>
                                <td width="20%">
                                    <v-btn
                                    color="warning"
                                    outlined
                                    fab
                                    x-small
                                    @click="editLab(item)">
                                    <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn color="error"
                                    outlined
                                    fab x-small
                                    @click="dropLab(item)">
                                    <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 "
                class="c-btn c-btn--primary mr-2"
                text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import Service from '../../services/apis';

export default {
  data: () => ({
    dialog: false,
    form_lab: {
      cliente_id: 1,
    },
    listlab: [],
    loading5: false,
    snack: {
      snackbar: false,
      txt: null,
      color: 'red',
      error: false,
      x: null,
      y: 'top',
    },
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  created() {
  },
  methods: {
    openmodal() {
      this.dialog = true;
    },
    async savenewLab() {
      // if(this.form_lab.name === '') {
      //   return false;
      // }
      this.loading5 = true;
      const nuevolab = await Service.apiToken('POST', 'new-lab', this.$ls.storage.token, this.form_lab);
      this.snack.snackbar = true;
      this.snack.color = 'success';
      this.snack.txt = 'Nuevo laboratorio registrado';
      // push al objeto de laboratorios
      this.data_ensayo.laboratoriosList.push(nuevolab.data);
      // vaciar formulario de lab
      this.form_lab.direccion = '';
      this.form_lab.name = '';
      this.loading5 = false;
    },
    async editLab(item) {
      await Service.apiToken('POST', 'edit-lab', this.$ls.storage.token, item);
    },
    async dropLab(item) {
      await Service.apiToken('POST', 'delete-lab', this.$ls.storage.token, item);
      let key = null;
      this.data_ensayo.laboratoriosList.map((lab, index) => {
        if (item.id === lab.id) {
          key = index;
        }
        return true;
      });
      this.data_ensayo.laboratoriosList.splice(key, 1);
    },
  },
};
</script>
